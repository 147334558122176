$.extend( true, $.fn.DataTable.defaults, {
  responsive: true,
  processing: true,
  autoWidth: false,
  stateSave: true,
  scrollX: true,
  pagingType: "dont_show_one",
  dom: "<'row'<'col-6 pr-0'f><'col-6 text-right'l>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
  language: {
    processing: "<label class='m-3 h5'><i class='fa fa-cog fa-spin'></i> Processing...</label>",
    search: "<span class='fa fa-search form-control-feedback'></span>",
    lengthMenu: "<i class='fa fa-bars' title=Rows count'></i> _MENU_ ",
    info: "<small class='text-muted'>Rows _START_ to _END_ from _TOTAL_</small>",
    infoEmpty: "",
    infoFiltered: "",
    infoPostFix: "",
    zeroRecords: "<label class='subheader'>no data</small>",
    paginate: {
      first: "First",
      previous: "<i class='fa fa-angle-double-left'></i>",
      next: "<i class='fa fa-angle-double-right'></i>",
      last: "Last"
    }
  },
});

$.fn.DataTable.ext.pager.dont_show_one = function(page, pages){
  var numbers = [];
  var buttons = $.fn.DataTable.ext.pager.numbers_length;
  var half = Math.floor( buttons / 2 );
  var _range = function ( len, start ){
    var end;
    if ( typeof start === "undefined" ){
      start = 0;
      end = len;
    } else {
      end = start;
      start = len;
    }
    var out = [];
    for ( var i = start ; i < end; i++ ){ out.push(i); }
    return out;
  };
  if (pages <= buttons) {
    numbers = _range(0, pages);
  } else if(page <= half) {
    numbers = _range(0, buttons - 2);
    numbers.push("ellipsis");
    numbers.push(pages - 1);
  } else if(page >= pages - 1 - half) {
    numbers = _range(pages - (buttons - 2), pages);
    numbers.splice(0, 0, "ellipsis");
    numbers.splice(0, 0, 0);
  } else {
    numbers = _range(page - 1, page + 2);
    numbers.push("ellipsis");
    numbers.push(pages - 1);
    numbers.splice(0, 0, "ellipsis");
    numbers.splice(0, 0, 0);
  }
  numbers.DT_el = 'span';
  return pages <= 1 ? '' : [ 'previous', numbers, 'next' ];
}

$(document).on('shown.bs.modal', function (e) {
  $.fn.dataTable.tables( {visible: true, api: true} ).columns.adjust();
});

window.addEventListener('resize', function(event){
  $.fn.dataTable.tables( {visible: true, api: true} ).columns.adjust();
});
