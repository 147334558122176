$.fn.modal.Constructor.Default.backdrop = 'static'; //не закрывать окна при клике мимо

// фокус на первый инпут в окне
$(document).on('shown.bs.modal', function() {
  $('.modal :input:not([type=image],[type=button],[type=submit]):enabled:visible:first').focus();
});

function bootstrap_validations(){
  var forms = document.getElementsByClassName('needs-validation');
  Array.prototype.filter.call(forms, function(form) {
    form.addEventListener('submit', function(event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  });

  $('.modal').on('shown.bs.modal', function (e) {
    $('.modal :input:not([type=image],[type=button],[type=submit]):enabled:visible:first').focus();
  })
};

window.bootstrap_validations = bootstrap_validations;
